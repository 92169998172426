<script setup lang="ts">
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { ref } from 'vue'
import { useLinkStore } from '../store/links';
import { mdiChevronLeft } from '@mdi/js'

const route = useRoute();
const linkStore = useLinkStore();

let channelId = route.params.channelId as string;
let linkId = route.params.linkId as string;
linkStore.fetchLink(linkId);

const { link } = storeToRefs(linkStore);

const valid = ref(true);
const isLoading = ref(false);
const showSnackbar = ref(false);
const isSaveSuccess = ref(true);
const typeItems = ref([
    {name: 'Product', value: 'product'},
    {name: 'Affliate', value: 'affliate'},
    {name: 'Sponsered', value: 'sponsered'},
    {name: 'Social Media', value: 'social'},
    {name: 'Support Platform', value: 'support_platform'},
    {name: 'Podcast', value: 'podcast'},
    {name: 'Article', value: 'article'},
    {name: 'Other', value: 'other'},
]);

function onSubmit() {
    console.log(link.value);
    
    isLoading.value = true;
    linkStore.updateLink(link.value).then(() => {
        isLoading.value = false;
        isSaveSuccess.value = true;
    })
    .catch(() => isSaveSuccess.value = false)
    .finally(() => showSnackbar.value = true);
}
</script>

<template>
<router-link :to="`/channels/${channelId}`" :replace="true" class="back-link">
    <v-icon :icon="mdiChevronLeft" size="large" color="blue-grey-darken-2"></v-icon>
    Channel page
</router-link>

<!--
<div class="channel-brand">
    <img class="channel-avatar" :src="link.channel.avatarUrl" :alt="link.channel.name">
    <div class="channel-name">
        <h2>{{link.channel.name}}</h2>
    </div>
</div>
-->

<div>
    <v-form v-model="valid" @submit.prevent="onSubmit">
        <v-container>
            <v-row>
                <v-col
                    cols="12"
                    md="2">
                    <img :src="link.imageUrl" class="link-image"/>
                </v-col>
                <v-col
                    cols="12"
                    md="10">
                    <v-text-field
                        v-model="link.imageUrl"
                        label="Image URL">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-text-field
                    v-model="link.name"
                    label="Name">
                </v-text-field>
            </v-row>
            <v-row>
                <v-text-field
                    v-model="link.url"
                    label="URL"
                    disabled
                    required>
                </v-text-field>
            </v-row>
            <v-row>
                <v-select
                    v-model="link.type"
                    :items="typeItems"
                    item-title="name"
                    item-value="value"
                    label="Link type">
                </v-select>
            </v-row>
            
            <div v-if="link.type == 'sponsered'">
                <v-text-field
                    v-model="link.discount.amount"
                    label="Discount value">
                </v-text-field>
                <v-text-field
                    v-model="link.discount.code"
                    label="Discount code">
                </v-text-field>
            </div>
            
            <v-row justify="end">
                <v-btn type="submit" :loading="isLoading">Save</v-btn>
            </v-row>
        </v-container>
    </v-form>
</div>
<v-snackbar
    v-model="showSnackbar"
>
    <span v-if="isSaveSuccess">Successfully saved changes.</span>
    <span v-else>Failed to save changes.</span>

    <template v-slot:actions>
    <v-btn
        color="pink"
        variant="text"
        @click="showSnackbar = false"
    >
        Close
    </v-btn>
    </template>
</v-snackbar>

</template>

<style scoped>
.channel-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.channel-name {
    text-align: left;
}
.channel-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.video-title {
    text-align: left;
}

.link-image {
    max-height: 75px;
}

.back-link {
    display: flex;
}
</style>