import { createRouter, createWebHistory } from 'vue-router';
import ChannelSettingsPage from './views/ChannelSettingsPage.vue';
import ListPage from './views/ListPage.vue';
import LinkPage from './views/LinkPage.vue';
import ChannelLinksPage from './views/ChannelLinksPage.vue';
import { useUserStore } from './store/user';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('./views/HomePage.vue'),
        },
        {
            path: '/channels/:channelId',
            name: 'channel',
            component: () => import('./views/ChannelPage.vue'),
        },
        /*
        {
            path: '/channels/:channelId/metrics/',
            name: 'metrics',
            component: ChannelMetricsPage,
            beforeEnter: (to, from) => {
                // check that the user owns the channel
                return false
            },
            beforeRouteUpdate: (to, from) => {
                return false
            }
        },
        */
        {
            path: '/channels/:channelId/lists/:listId',
            name: 'list',
            component: ListPage,
        },
        {
            path: '/channels/:channelId/links/',
            name: 'links',
            component: ChannelLinksPage
        },
        {
            path: '/channels/:channelId/links/:linkId',
            name: 'link',
            component: LinkPage
        },
        {
            path: '/channels/:channelId/videos/:videoId',
            name: 'video',
            component: () => import('./views/VideoPage.vue'),
        },
        {
            path: '/channels/:channelId/settings/',
            name: 'channel-settings',
            component: ChannelSettingsPage,
            meta: {isAdmin: true}
        },
    ]
});

router.beforeEach((to, _) => {
    if (to.meta.isAdmin) {
        const userStore = useUserStore();
        if (userStore.user && userStore.user.isAdmin) {
            return true;
        } else {
            return false;
        }
    }
});

export default router;